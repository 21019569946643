import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import BmPage from "src/components/bmPage"
import FfDisclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const BmData = import("./data/data.json");
const footer = <RawFooter></RawFooter>

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Find Manuals to Read & Print | manualslibrary.co</title>
    <style type="text/css">{`
    #bmpage-module--bmPage p {
      padding: 10px 7%;
      font-size: 12px;
      color: #fff;
      text-align: center;
      margin-top: 15px;
      max-width: 650px;
      font-weight:400;
    }
   footer {
    font-family: 'Roboto', sans-serif !important;
    display: block;
    position: absolute;
    color: #333;
    right: 15px;
    bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    width: 520px;
  }
  footer ul {
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    text-align: right;
    color: #333;
    font-size: 12px;
    padding: 0 0 12px 0;
  }
  footer ul li {
    padding: 0 4px 0 0;
  }
  footer ul li:last-child {
    padding: 0;
  }
  footer ul li:after {
    content: '|';
    padding: 0 0 0 4px;
  }
  footer ul li:nth-child(6):after, footer ul li:nth-child(7):after, footer ul li:last-child:after {
    content: none;
    padding: 0;
  }
  footer ul a {
    color: #333;
  }
  @media only screen and (max-width: 768px) {

    footer {
      position: relative;
      display: flex;
      justify-content:center;
      align-items:center;
      color: #333;
      width: 100%;
      right:auto;
      bottom:auto;
  }

  footer ul {
    position: relative;
    display: flex;
    justify-content:center;
    align-items:center;
}
    `}
    </style>
    </Helmet>
    <section>
      <BmPage data={BmData} footer={footer}><FfDisclosure></FfDisclosure></BmPage>
      </section>
    </HomepageLayout>

  )
  }
